@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.cta {
  padding: 8px 25px;
  font-size: 25px;
  color: $c-white;
  background-color: $c-dark-blue;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: Arial, Helvetica, sans-serif;

  &:hover {
    color: $c-white;
    background-color: $c-dark-red;
  }
}
