@import "../../styles/colors.scss";
@import "../../styles/media.scss";
@import "../../styles/typography.scss";

.about-us {
  padding: 110px 20px 60px 20px;
  background-color: $c-dark-blue;

  &__title-wrapper {
    margin: 10px 20px 15px 0px;
    color: $c-white;
    width: 100%;

    @include mq-phone-up {
      margin: 10px 20px 15px 20px;
    }

    @include mq-laptop-up {
      width: 1000px;
      margin: 10px auto 15px;
    }

    @include mq-desktop-up {
      width: 1281px;
    }
  }

  &__title {
    @extend .title-large;
  }

  &__content-wrapper {
    border-top: 1px solid $c-white;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    padding: 40px 0px;
    color: $c-white;

    @include mq-phone-l-up {
      width: 600px;
    }

    @include mq-laptop-up {
      width: 800px;
    }

    @include mq-desktop-up {
      width: 1000px;
    }

    li {
      font-family: "Segoe UI", Arial, sans-serif;
      font-size: 20px;
      margin: 20px 0px 0px 20px;
      line-height: 1.2;

      span {
        // padding: 3px 10px 5px 10px;
        // background-color: rgba($c-dark-red, 0.7);
        // border-radius: 20px;
      }
    }
  }

  &__image {
    width: 100%;
    margin: 60px auto 0 auto;
    display: block;
    border: 2px solid $c-white;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 3px $c-black;

    @include mq-phone-l-up {
      width: 600px;
    }

    @include mq-laptop-up {
      width: 1000px;
    }

    @include mq-desktop-up {
      width: 50%;
    }
  }
}
