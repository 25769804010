@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.masthead {
  z-index: 111;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  &--scrolled {
    @include mq-phone-up {
      .masthead__logo-wrapper {
        display: none;
      }
    }

    @include mq-laptop-up {
      .masthead__top-wrapper {
        height: 35px;
        font-size: 17px;
      }
      .masthead__contact-item--email {
        font-size: 17px;
      }
      .masthead__social-item {
        font-size: 20px;
      }
      .masthead__header-wrapper {
        background-color: rgba(255, 255, 255, 0.9);
        border-bottom: 1px solid $c-black;
        // box-shadow: 0 1px 1px 0 $c-dark-blue;
      }
      .masthead__header {
        top: 45px;
        height: 50px;
      }
      .masthead__logo-wrapper {
        display: flex;
      }
      .masthead__logo {
        width: 185px;
      }
      .masthead__nav-link {
        color: $c-dark-blue;

        &:hover {
          background: $c-dark-blue;
          color: $c-white;
        }
      }
    }
  }

  &__top-wrapper {
    height: 35px;
    font-size: 17px;
    background: $c-dark-blue; /*#ff2800*/ /*crvena: dd0101 plava: 0e1f4a 182D62*/
    color: $c-white;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    transition: height 0.2s;
    padding: 0 10px;

    @include mq-phone-up {
      padding: 0px;
    }

    @include mq-laptop-up {
      height: 45px;
      font-size: 20px;
    }
  }

  &__top {
    display: flex;
    justify-content: end;

    @include mq-phone-up {
      justify-content: space-between;
    }

    @include mq-laptop-up {
    }
  }

  &__contact-item {
    display: flex;
    align-items: center;

    &:hover {
      color: $c-light-blue;
      transition: color 0.2s;
    }

    &--phone-mobile {
      margin-right: 10px;

      @include mq-phone-up {
        margin-right: 0px;
      }

      svg {
        animation: ringing;
        animation-duration: 3s;
        animation-delay: 2s;
        animation-iteration-count: infinite;
      }
    }

    &--phone {
      margin-right: auto;

      @include mq-phone-up {
        margin-right: 0px;
      }

      @include mq-laptop-up {
        margin-left: 30px;
      }

      svg {
        animation: ringing;
        animation-duration: 3s;
        animation-delay: 2s;
        animation-iteration-count: infinite;
      }
    }

    &--email {
      font-size: 25px;

      @include mq-phone-up {
        font-size: 17px;
      }

      @include mq-laptop-up {
        font-size: 20px;
        margin-left: 30px;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    display: none;

    @include mq-laptop-up {
      display: block;
      margin-left: auto;
    }
  }

  &__social-item {
    display: flex;
    font-size: 25px;
    margin-left: 10px;

    svg {
      height: 100%;
    }

    @include mq-phone-up {
      margin-left: 20px;
    }

    &:hover {
      color: $c-light-blue;
      transition: 0.2s;
    }
  }

  &__hamburger {
    display: block;
    z-index: 1;

    @include mq-phone-up {
      display: none;
    }

    div {
      width: 27px;
      height: 4px;
      background-color: $c-black;
      margin: 5px 0;
      border-radius: 20px;
      background: $c-dark-blue;
    }
  }

  &__header-wrapper {
    background-color: $c-white;

    @include mq-laptop-up {
      background-color: transparent;
      // background-color: rgba($c-white, 0.3);
      // box-shadow: 0 2px 2px 0 $c-dark-blue;
    }
  }

  &__header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    transition: height 0.2s;
    position: relative;
    padding: 0 10px 0 0;

    @include mq-phone-up {
      position: static;
      flex-direction: column;
      padding: 0px;
    }

    @include mq-laptop-up {
      height: 90px;
      align-items: stretch;
      flex-direction: row;
    }
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
  }

  &__logo {
    position: relative;
    top: -1px;
    transition: width 0.2s;
    width: 200px;
    height: fit-content;

    @include mq-laptop-up {
      width: 280px;
      left: -5px;
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    @include mq-phone-up {
      display: flex;
      justify-content: flex-start;
      position: static;
      height: 50px;
      border-top: 1px solid $c-dark-blue;
    }

    @include mq-laptop-up {
      height: auto;
      border-top: none;
    }
  }

  &__nav-items {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: column;
    width: 100%;
    background-color: $c-white;

    @include mq-phone-up {
      flex-direction: row;
      background-color: transparent;
    }

    li {
      display: flex;
      align-items: center;
    }

    li:nth-of-type(1) {
      a {
        margin-left: 0px;
      }
    }
  }

  &__nav-link {
    color: $c-dark-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 15px 0px;
    font-size: 17.5px;
    box-shadow: 0 0.8px 0.8px 0 $c-dark-blue;

    @include mq-phone-up {
      justify-content: flex-start;
      margin-left: 15px;
      padding: 0px 10px;
      box-shadow: none;
    }
    @include mq-laptop-up {
      height: auto;
      border-radius: 20px;
      padding: 10px 15px;
      color: $c-white;
      transition: 0.1s;
    }

    &:hover {
      background: $c-dark-blue;
      color: $c-white;
    }
  }

  &__email-text {
    display: none;

    @include mq-phone-up {
      display: block;
    }
  }
}

@keyframes ringing {
  2% {
    transform: rotate(15deg);
  }
  4%,
  8%,
  12%,
  16% {
    transform: rotate(10deg);
  }
  6%,
  10%,
  14%,
  18% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
