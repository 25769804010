// Variables
$column-phone: 500px;
$column-laptop: 1000px;
$column-desktop: 1281px;

// Max break point
$break-xs: 450px;
$break-s: 576px;
$break-sm: 650px;
$break-m: 767px;
$break-l: 1024px;
$break-xl: 1440px;

@mixin mq-phone-xs-up {
  @media screen and (min-width: $break-xs) {
    @content;
  }
}

@mixin mq-phone-up {
  @media screen and (min-width: $break-s) {
    @content;
  }
}

@mixin mq-phone-l-up {
  @media screen and (min-width: $break-sm) {
    @content;
  }
}

@mixin mq-tablet-up {
  @media screen and (min-width: $break-m) {
    @content;
  }
}

@mixin mq-laptop-up {
  @media screen and (min-width: $break-l) {
    @content;
  }
}

@mixin mq-desktop-up {
  @media screen and (min-width: $break-xl) {
    @content;
  }
}
