@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.hero {
  width: 100%;
  position: relative;
  top: 90px;
  aspect-ratio: 1/1;

  @include mq-phone-up {
    aspect-ratio: 16/10;
  }

  @include mq-laptop-up {
    aspect-ratio: 16/9;
    top: auto;
  }

  @include mq-desktop-up {
    aspect-ratio: 16/7.5;
  }

  &__slider {
    width: 100%;
    height: 100%;
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $c-dark-blue;
    height: auto;
  }

  &__slide-text-wrapper {
    position: absolute;
    padding: 50px 10px;
    color: $c-white;
    opacity: 0;
    transition: opacity 500ms cubic-bezier(0.61, 0.25, 0.51, 0.74),
      transform 500ms cubic-bezier(0.61, 0.25, 0.51, 0.74);
    transform: translateY(50px);

    &--active {
      opacity: 1;
      transform: translateY(0px);
    }

    @include mq-phone-up {
      width: $column-phone;
    }

    @include mq-laptop-up {
      width: $column-laptop;
      top: 20%;
      left: auto;
    }

    @include mq-desktop-up {
      width: $column-desktop;
    }
  }

  &__slide-title {
    @include mq-phone-up {
      font-size: 45px;
    }

    @include mq-laptop-up {
      font-size: 65px;
    }

    @include mq-desktop-up {
      font-size: 75px;
    }
  }

  &__slide-desc {
    font-size: 20px;
    margin: 0 0 20px 0;
    width: 250px;
    line-height: 2;

    @include mq-phone-xs-up {
      width: 350px;
    }

    @include mq-phone-up {
      width: 500px;
    }

    @include mq-laptop-up {
      font-size: 25px;
    }

    @include mq-desktop-up {
    }
  }

  &__slide-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__navigation-button {
    &--prev,
    &--next {
      z-index: 11;
      cursor: pointer;
      position: absolute;
      top: 50%;
      margin-top: -42px;
      left: -5px;
      width: auto;
      padding: 16px;
      color: white;
      font-size: 35px;
      transition: background-color 0.3s ease;
      border: none;
      border-radius: 0 3px 3px 0;
      // background-color: rgba(14, 31, 74, 0.8);
      background-color: transparent;

      &:hover {
        background-color: rgba(14, 31, 74, 1);
      }
    }

    &--next {
      left: auto;
      right: 0;
      border-radius: 3px 0 0 3px;
    }
  }
}

.swiper-slide {
  height: auto !important;
}
