.title-small {
  font-size: 20px;
}
.title-medium {
  font-size: 24px;
}
.title-large {
  font-size: 28px;
}

// @font-face {
//   font-family: "Font-1";
//   src: url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@500&display=swap");
// }
