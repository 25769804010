@import "../../styles/colors.scss";
@import "../../styles/media.scss";
@import "../../styles/typography.scss";

.footer {
  padding-top: 50px;
  padding-bottom: 30px;
  background: $c-black;
  color: $c-white;
  border-top: 1px solid $c-white;
  position: relative;

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 40px;
    width: 100%;
    margin: 0px auto;

    @include mq-laptop-up {
      padding: 0px;
      width: 1000px;
      flex-direction: row;
    }

    @include mq-desktop-up {
      width: 1281px;
    }
  }

  &__logo {
    position: relative;
    left: -10px;

    @include mq-laptop-up {
      align-self: flex-end;
      position: static;
    }

    img {
      width: 200px;

      @include mq-laptop-up {
        width: 300px;
      }
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $c-white;
    width: 100%;
    margin: 0 auto;

    @include mq-laptop-up {
      width: 1000px;
    }

    @include mq-desktop-up {
      width: 1281px;
    }

    p {
      text-align: center;
      margin-bottom: 0;
    }
  }

  &__back-to-top {
    align-self: flex-end;
    color: $c-white;
    padding: 15px 20px;
    border: 2px solid $c-white;
    position: absolute;
    top: -28px;
    right: 50px;
    background: $c-dark-blue;
    transition: background-color 0.2s;
    border-radius: 29px;

    &:hover {
      background-color: $c-dark-red;
    }
  }

  &__me {
    padding: 5px;
    border-radius: 13px;
    transition: background-color 0.2s;
    &:hover {
      background-color: $c-dark-red;
    }
  }
}
