@import "../../styles/colors.scss";
@import "../../styles/media.scss";
@import "../../styles/typography.scss";

.contact {
  background-color: $c-dark-blue;
  color: $c-white;
  padding: 110px 20px 0px 20px;

  @include mq-phone-up {
    margin: 0px;
  }

  &__title-wrapper {
    margin: 10px 20px 15px 0px;
    color: $c-white;
    width: 100%;

    @include mq-phone-up {
      margin: 10px 20px 15px 20px;
    }

    @include mq-laptop-up {
      width: 1000px;
      margin: 10px auto 15px;
    }

    @include mq-desktop-up {
      width: 1281px;
    }
  }

  &__title {
    @extend .title-large;
  }

  &__map {
    padding-top: 20px;
    border-top: 1px solid $c-white;

    @include mq-phone-up {
      margin: 0 20px;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;

    @include mq-phone-l-up {
      width: 600px;
      align-items: center;
    }

    @include mq-laptop-up {
      width: 1000px;
      flex-direction: row;
      align-items: normal;
    }

    @include mq-desktop-up {
      width: $column-desktop;
    }
  }

  &__form-title {
    @extend .title-large;
    font-family: "Segoe UI", Arial, sans-serif;
  }

  &__form-items-wrapper {
    display: flex;
    flex-direction: column;

    @include mq-phone-l-up {
      flex-direction: row;
    }
  }

  &__form-item {
    input {
      line-height: 2;
      border-radius: 4px;
    }

    &--verify-error {
      input {
        border: 2px solid red;
      }

      .contact__error-message {
        display: block;
      }
    }
  }

  &__error-message {
    display: none;
  }

  #form-name-input {
    margin-right: 20px;
    width: 100%;

    @include mq-phone-l-up {
      width: 240px;
    }
  }

  #form-mail-input {
    width: 100%;

    @include mq-phone-l-up {
      width: 300px;
    }

    @include mq-tablet-up {
      width: 330px;
    }
  }

  #form-subject-input {
    width: 100%;

    @include mq-phone-l-up {
      width: 560px;
    }

    @include mq-tablet-up {
      width: 590px;
    }
  }

  #form-message-input {
    resize: vertical;
    width: 100%;
    border-radius: 4px;
    font-size: 16px;

    @include mq-phone-l-up {
      width: 560px;
    }

    @include mq-tablet-up {
      width: 590px;
    }
  }

  #form-error-input {
    width: 100%;

    @include mq-phone-l-up {
      width: 560px;
    }

    @include mq-tablet-up {
      width: 590px;
    }
  }

  &__form-submit-button {
    @extend .title-large;
    margin-top: 20px;
    padding: 8px 12px;
    font-size: 22px;
    font-family: "Archivo Narrow", sans-serif;
    font-weight: normal;
    cursor: pointer;
    // border: 1px solid $c-white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      color: $c-white;
      background-color: $c-dark-red;
    }
  }

  &__form-label {
    display: block;
    margin: 15px 0px;
  }

  &__aside {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;

    @include mq-phone-l-up {
      flex-direction: row;
    }

    @include mq-laptop-up {
      width: auto;
      flex-direction: column;
      padding: 5px 20px 20px;
      margin-top: 0px;
    }

    p {
      font-size: 20px;
    }
  }

  &__aside-item {
    margin-top: 50px;

    a {
      transition: color 0.2s;
      &:hover {
        color: $c-light-blue;
      }
    }
  }

  &__aside-title {
    @extend .title-large;
  }

  &__aside-social-item {
    transition: color 0.2s;

    svg {
      width: 40px;
      height: 40px;
    }

    &:first-child() {
      margin-left: 0px;
    }
  }

  &__message-sent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $c-white;
    color: $c-dark-blue;
    border: 3px solid $c-dark-blue;
    padding: 20px 30px;
    font-size: 18px;
    border-radius: 5px;
    width: max-content;
    max-width: 98%;
    z-index: 111;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: bold;
    }

    button {
      font-size: 24px;
      margin: 0 auto;
      margin-top: 20px;
      border: none;
      padding: 10px 15px;
      border-radius: 3px;
      background-color: $c-dark-red;
      color: $c-white;
      cursor: pointer;
    }
  }
}
