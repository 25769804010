@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.gdpr {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: $c-dark-blue;
  color: $c-white;
  z-index: 111;
  border: 1px solid $c-white;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--active {
    top: 0px;
    overflow: auto;
  }

  &__cta {
    padding: 8px 25px;
    font-size: 20px;
    color: $c-white;
    background-color: $c-dark-blue;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    transition: border 0.2s;
    font-family: Arial, Helvetica, sans-serif;
  }

  &__cta:nth-of-type(1) {
    background-color: $c-dark-red;
    margin-right: 10px;

    &:hover {
      border: 1px solid $c-white;
    }
  }

  &__more-content-wrapper {
    text-align: left;
    overflow: hidden;
    height: 0px;

    &--read-more {
      height: auto;
      padding: 40px 20px;
      overflow: auto;
    }
  }

  &__link {
    text-decoration: underline;
  }
}
