@import "../../styles/colors.scss";
@import "../../styles/media.scss";
@import "../../styles/typography.scss";

.parts {
  padding: 110px 20px 90px 20px;
  border-top: 1px solid $c-white;

  &__content-wrapper {
    padding-top: 20px;
    border-top: 1px solid white;
  }

  &__content {
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;

    @include mq-phone-l-up {
      width: 600px;
      align-items: center;
    }

    @include mq-laptop-up {
      width: 1000px;
      flex-direction: row;
      align-items: normal;
    }

    @include mq-desktop-up {
      width: $column-desktop;
    }
  }

  &__title-wrapper {
    margin: 10px 20px 15px 0px;
    color: $c-white;
    width: 100%;

    @include mq-phone-up {
      margin: 10px 20px 15px 20px;
    }

    @include mq-laptop-up {
      width: 1000px;
      margin: 10px auto 15px;
    }

    @include mq-desktop-up {
      width: 1281px;
    }
  }

  &__title {
    @extend .title-large;
  }

  &__types {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  &__types-title {
    color: $c-white;
    margin-top: 20px;
    text-align: center;
  }

  &__type {
    font-family: "Archivo Narrow", sans-serif;
    color: $c-dark-blue;
    font-size: 48px;
    background-color: $c-white;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 3px $c-dark-red;
    margin: 20px;
  }

  &__subtypes-title {
    color: $c-white;
    margin-top: 80px;
  }

  &__subtypes {
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__subtype {
    font-family: "Archivo Narrow", sans-serif;
    color: $c-white;
    font-size: 26px;
    background-color: $c-dark-red;
    padding: 5px 15px;
    border-radius: 4px;
    margin: 10px 30px;
    // border: 1px solid $c-white;
    // box-shadow: 1px 1px 3px 3px $c-dark-red;
  }

  &__slider {
    height: 500px;
    margin-top: 60px;
  }

  &__slide {
    border-radius: 10px;
    border: 2px solid $c-white;
    overflow: hidden;
    box-shadow: 1px 1px 3px 3px $c-black;

    &:hover {
      .parts__slide-img {
        transform: scale(1.2);
      }
      // .parts__slide-text-wrapper {
      //   transform: translateY(100%);
      // }
      .parts__slide-desc {
        opacity: 1;
      }
    }
  }

  &__navigation-button {
    &--prev,
    &--next {
      z-index: 11;
      cursor: pointer;
      position: absolute;
      top: 50%;
      margin-top: -42px;
      left: -5px;
      width: auto;
      padding: 16px;
      color: white;
      font-size: 35px;
      transition: background-color 0.3s ease;
      border: none;
      border-radius: 0 3px 3px 0;
      // background-color: rgba(14, 31, 74, 0.8);
      background-color: rgba(14, 31, 74, 1);
    }

    &--next {
      left: auto;
      right: 0;
      border-radius: 3px 0 0 3px;
    }
  }

  &__slide-text-wrapper {
    position: absolute;
    bottom: 0;
    color: $c-dark-blue;
    padding: 20px;
    border-top: 1px solid $c-white;
    width: 100%;
    background-color: rgba($c-white, 0.8);
    z-index: 1111;
    transition: transform 0.3s;
  }

  &__slide-title {
    font-size: 30px;
  }

  &__slide-img {
    transition: transform 0.3s;
    transform: scale(1);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // &__slide-desc {
  //   position: absolute;
  //   opacity: 0;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: 1111;
  //   background-color: rgba($c-white, 0.8);
  //   transition: opacity 0.3s;
  //   margin: 0;
  //   padding: 10px;
  //   font-size: 24px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
}
