@import "./styles/colors.scss";
@import "./styles/media.scss";
@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@500&display=swap");

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  height: 2000px;
  font-family: "Archivo Narrow", sans-serif;
  background-color: #0e1f4a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-family: "Segoe UI", Arial, sans-serif;
}

a {
  text-decoration: none;
  color: $c-white;
}

.column-control {
  width: 100%;
  margin: 0 auto;

  @include mq-phone-up {
    width: $column-phone;
  }

  @include mq-laptop-up {
    width: $column-laptop;
  }

  @include mq-desktop-up {
    width: $column-desktop;
  }
}
